import { Breadcrumbs } from 'components/_layout/Breadcrumbs/Breadcrumbs';
import React, { useMemo, useRef, useState } from 'react';
import ImageMapper, { AreaEvent, CustomArea } from 'react-img-mapper';
import { ArrowLeft } from 'shared/svg/arrows';
import { GarageType, StorageType } from 'types/CommonTypes';
import {
    HeroLink,
    HeroTitle,
    HeroWrapper,
    ImageMapperWrapper,
    InfoBox,
    InfoBoxWrapper,
    LocalWrapper,
    StyledInfoBoxTitle,
    StyledStatusText,
    StyledTooltipFlatStatus,
    StyledTooltipLocal,
    StyledTooltipText,
    StyledtStatusTextFree,
    StyledtStatusTextReserve,
    StyledtStatusTextSold,
    Tooltip,
    Wrapper,
} from './GarageHero.styled';
import { useContainerDimensions } from 'utils/hooks/useContainerDimensions';
import { useImageRatio } from 'utils/hooks/useImageRatio';

interface GarageHeroProps {
    availableGarages: number;
    availableStorageRooms: number;
    bookedGarages: number;
    bookedStorageRooms: number;
    breadcrumbText: string;
    investmentSlug: string;
    searchGarageOrStorageByImage: any;
    soldGarages: number;
    soldStorageRooms: number;
}

interface StateTypes {
    localNumber: string | null;
    price: string | null;
    status: string | null;
    tooltipIsVisible: boolean;
    type: string | null;
    yardage: string | null;
}

export const GarageHero = ({
    availableGarages,
    availableStorageRooms,
    bookedGarages,
    bookedStorageRooms,
    breadcrumbText,
    investmentSlug,
    searchGarageOrStorageByImage,
    soldGarages,
    soldStorageRooms,
}: GarageHeroProps) => {
    const garageSearchMap = useMemo(() => {
        return searchGarageOrStorageByImage?.garagesSearchMap
            ? searchGarageOrStorageByImage?.garagesSearchMap?.map(
                  (item: GarageType) => ({
                      coords: item?.coordinates
                          ? item?.coordinates?.split(',')?.map((i) => Number(i))
                          : [],
                      id: 'G' + item?.garage?.investmentGarage?.garageNumber,
                      shape: 'poly',
                      preFillColor:
                          item?.garage?.investmentGarage?.garageStatus?.name ===
                          'Wolne'
                              ? '#46A25A70'
                              : item?.garage?.investmentGarage?.garageStatus
                                    ?.name === 'Sprzedane'
                              ? '#DD432670'
                              : '#E0B85270',
                      status: item?.garage?.investmentGarage?.garageStatus
                          ?.name,
                      area: item?.garage?.investmentGarage?.garageArea,
                      number: item?.garage?.investmentGarage?.garageNumber,
                      price: item?.garage?.investmentGarage?.garagePrice,
                      href: '',
                  }),
              )
            : [];
    }, [searchGarageOrStorageByImage]);

    const storageSearchMap = useMemo(() => {
        return searchGarageOrStorageByImage?.storagesSearchMap
            ? searchGarageOrStorageByImage?.storagesSearchMap?.map(
                  (item: StorageType) => ({
                      coords: item?.coordinates
                          ? item?.coordinates?.split(',')?.map((i) => Number(i))
                          : [],
                      id:
                          'S' +
                          item?.storageRoom?.investmentStorageRoom
                              ?.storageRoomNumber,
                      shape: 'poly',
                      preFillColor:
                          item?.storageRoom?.investmentStorageRoom
                              ?.storageRoomStatus.name === 'Wolne'
                              ? '#46A25A70'
                              : item?.storageRoom?.investmentStorageRoom
                                    ?.storageRoomStatus?.name === 'Sprzedane'
                              ? '#DD432670'
                              : '#E0B85270',
                      status: item?.storageRoom?.investmentStorageRoom
                          ?.storageRoomStatus.name,
                      area: item?.storageRoom?.investmentStorageRoom
                          ?.storageRoomArea,
                      number: item?.storageRoom?.investmentStorageRoom
                          ?.storageRoomNumber,
                      price: item?.storageRoom?.investmentStorageRoom
                          ?.storageRoomPrice,
                      href: '',
                  }),
              )
            : [];
    }, [searchGarageOrStorageByImage]);

    const [tooltip, setTooltip] = useState<StateTypes>({
        localNumber: '',
        price: '',
        status: '',
        tooltipIsVisible: false,
        type: '',
        yardage: '',
    });
    const tooltipRef = useRef<HTMLSpanElement>(null);
    let cursorPositionX = 0;
    let cursorPositionY = 0;

    const imagesBoxRef = useRef<HTMLDivElement>(null);
    const { width } = useContainerDimensions(imagesBoxRef);
    const currentRatio = useImageRatio(
        searchGarageOrStorageByImage?.searchMapImage?.mediaDetails,
    );
    const imagesBoxWidth = width;
    const imagesBoxHeight = imagesBoxWidth * currentRatio;

    const moveOnArea = (area: CustomArea, index: number, event: AreaEvent) => {
        cursorPositionX = event.clientX;
        cursorPositionY = event.clientY;
        tooltipRef.current!.style.left = `${cursorPositionX}px`;
        tooltipRef.current!.style.top = `${cursorPositionY - 90}px`;
    };

    const enterArea = (area: any, index: number, event: AreaEvent) => {
        setTooltip({
            localNumber: area?.number,
            price: area?.price,
            status: area?.status,
            tooltipIsVisible: true,
            type: area?.id?.slice(0, 1) === 'S' ? 'Komórka' : 'Miejsce',
            yardage: area?.area,
        });
    };

    const leaveArea = () => {
        setTooltip({
            localNumber: null,
            price: null,
            status: null,
            tooltipIsVisible: false,
            type: null,
            yardage: null,
        });
    };

    return (
        <Wrapper>
            <Breadcrumbs text={breadcrumbText} />
            <HeroWrapper>
                <HeroTitle>Wybierz lokal</HeroTitle>
                <HeroLink to={'/' + investmentSlug + '/mieszkania'}>
                    <ArrowLeft />
                    Wróć do wyboru piętra
                </HeroLink>
            </HeroWrapper>
            <LocalWrapper>
                <ImageMapperWrapper ref={imagesBoxRef}>
                    <ImageMapper
                        imgWidth={
                            searchGarageOrStorageByImage?.searchMapImage
                                ?.mediaDetails?.width
                        }
                        onMouseEnter={(
                            area: CustomArea,
                            index: number,
                            event: AreaEvent,
                        ) => {
                            enterArea(area, index, event);
                        }}
                        onMouseLeave={() => leaveArea()}
                        onMouseMove={(
                            area: CustomArea,
                            index: number,
                            event: AreaEvent,
                        ) => moveOnArea(area, index, event)}
                        src={
                            searchGarageOrStorageByImage?.searchMapImage
                                ?.sourceUrl
                        }
                        fillColor={'rgb(0, 0, 0, 0.1)'}
                        width={imagesBoxWidth}
                        height={imagesBoxHeight}
                        map={{
                            name: 'garageAndStorage',
                            areas: garageSearchMap?.concat(storageSearchMap),
                        }}
                        key={'GarageAndStorage'}
                    />
                    <Tooltip
                        ref={tooltipRef}
                        isVisible={tooltip?.tooltipIsVisible}
                    >
                        <StyledTooltipLocal>
                            {tooltip?.type} {tooltip?.localNumber}
                        </StyledTooltipLocal>
                        <StyledTooltipFlatStatus isFree={tooltip?.status}>
                            {tooltip?.status}
                        </StyledTooltipFlatStatus>
                        <StyledTooltipText>
                            Metraż: {tooltip?.yardage} m<sup>2</sup>
                        </StyledTooltipText>
                        <StyledTooltipText>
                            Cena: {tooltip?.price ? tooltip?.price + 'zł' : '-'}
                        </StyledTooltipText>
                    </Tooltip>
                </ImageMapperWrapper>

                <InfoBoxWrapper>
                    <InfoBox>
                        <StyledInfoBoxTitle>Garaże</StyledInfoBoxTitle>
                        <StyledStatusText>
                            <StyledtStatusTextFree>Wolne</StyledtStatusTextFree>
                            {availableGarages}
                        </StyledStatusText>
                        <StyledStatusText>
                            <StyledtStatusTextReserve>
                                Zarezerwowane
                            </StyledtStatusTextReserve>
                            {bookedGarages}
                        </StyledStatusText>
                        <StyledStatusText>
                            <StyledtStatusTextSold>
                                Sprzedane
                            </StyledtStatusTextSold>
                            {soldGarages}
                        </StyledStatusText>
                    </InfoBox>
                    <InfoBox>
                        <StyledInfoBoxTitle>Komórki</StyledInfoBoxTitle>
                        <StyledStatusText>
                            <StyledtStatusTextFree>Wolne</StyledtStatusTextFree>
                            {availableStorageRooms}
                        </StyledStatusText>
                        <StyledStatusText>
                            <StyledtStatusTextReserve>
                                Zarezerwowane
                            </StyledtStatusTextReserve>
                            {bookedStorageRooms}
                        </StyledStatusText>
                        <StyledStatusText>
                            <StyledtStatusTextSold>
                                Sprzedane
                            </StyledtStatusTextSold>
                            {soldStorageRooms}
                        </StyledStatusText>
                    </InfoBox>
                </InfoBoxWrapper>
            </LocalWrapper>
        </Wrapper>
    );
};
