import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';
import { H2, H4, SectionText, SectionWrapper } from 'styles/Typography.styled';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 88px 136px;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
    ${mediaQueries.sm} {
        display: none;
    }
`;

export const HeroTitle = styled(H2)``;

export const HeroLink = styled(Link)(
    ({ theme: { fontSizes, fontWeight, letterSpacing, colors } }) =>
        css`
            align-items: center;
            color: ${colors.primaryBlue500};
            display: flex;
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.bold};
            letter-spacing: ${letterSpacing.s};
            line-height: 24px;
            white-space: nowrap;

            svg {
                margin-right: 8px;
            }
        `,
);

export const HeroWrapper = styled.div`
    width: 100%;
    padding: 40px 0 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LocalWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;

export const ImageMapperWrapper = styled.div`
    width: calc(100% - 364px);
`;

export const Tooltip = styled.span<{ isVisible: boolean }>(
    ({ theme: { colors }, isVisible }) => css`
        background: ${colors.primaryBlue50};
        color: ${colors.neutral900};
        min-width: 200px;
        padding: 24px;
        pointer-events: none;
        position: fixed;
        transform: translate3d(-50%, -50%, 0);
        visibility: ${isVisible ? 'visible' : 'hidden'};
        z-index: 1000;

        &:after {
            background: ${colors.primaryBlue50};
            bottom: -2px;
            content: '';
            height: 14px;
            left: 50%;
            position: fixed;
            transform: rotate(45deg) translate(-50%, 50%);
            width: 19px;
        }
    `,
);

export const StyledTooltipLocal = styled(H4)`
    margin-bottom: 8px;
`;

export const StyledTooltipText = styled(SectionText)(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.neutral500};
        font-weight: ${fontWeight.medium};
    `,
);

export const StyledTooltipFlatStatus = styled.p<{ isFree: string | null }>(
    ({ theme: { colors, fontWeight }, isFree }) => css`
        color: ${isFree === 'Wolne'
            ? colors.green
            : isFree === 'Sprzedane'
            ? colors.error
            : colors.yellow};
        font-weight: ${fontWeight.semiBold};
        padding-bottom: 16px;
    `,
);

export const InfoBoxWrapper = styled.div`
    width: 294px;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const InfoBox = styled.div(
    ({ theme: { colors } }) =>
        css`
            padding: 32px;
            border: 1px solid ${colors.neutral100};
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            :first-of-type {
                margin-bottom: 32px;
            }
        `,
);

export const StyledInfoBoxTitle = styled(H4)(
    ({ theme: { fontSizes, fontWeight, letterSpacing } }) => css`
        width: 100%;
        padding-bottom: 24px;
        font-size: ${fontSizes.f30};
        font-weight: ${fontWeight.bold};
        letter-spacing: ${letterSpacing.s};
    `,
);

export const StyledStatusText = styled(SectionText)(
    ({ theme: { fontSizes, fontWeight, letterSpacing, colors } }) => css`
        padding-bottom: 8px;
        color: ${colors.neutral900};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        letter-spacing: ${letterSpacing.s};
        display: flex;
        justify-content: space-between;
    `,
);

export const StyledtStatusTextFree = styled.span(
    ({ theme: { colors, fontWeight } }) => css`
        margin-right: auto;
        color: ${colors.green};
        font-weight: ${fontWeight.bold};
    `,
);
export const StyledtStatusTextReserve = styled.span(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.yellow};
        font-weight: ${fontWeight.bold};
    `,
);
export const StyledtStatusTextSold = styled.span(
    ({ theme: { colors, fontWeight } }) => css`
        color: ${colors.red};
        font-weight: ${fontWeight.bold};
    `,
);
