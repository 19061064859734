import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H2, SecondaryButton } from 'styles/Typography.styled';

export const Wrapper = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 88px 136px;

        ${mediaQueries.md} {
            padding: 56px 16px;
        }

        ${mediaQueries.sm} {
            width: 100%;
            margin: 0;
            padding: 0 0 42px 0;
            background-color: ${colors.primaryBlue60};
        }
    `,
);

export const TableGarageWrapper = styled.div<{ hideStoragesMap?: boolean }>(
    ({ hideStoragesMap }) => css`
        ${!hideStoragesMap && 'padding-bottom: 184px;'}
        overflow-x: auto;
        width: 100%;

        ${mediaQueries.sm} {
            display: block;
            padding-bottom: 28px;
        }
    `,
);

export const TableStorageWrapper = styled.div`
    padding-bottom: 12px;
    overflow-x: auto;
    width: 100%;

    ${mediaQueries.sm} {
        display: block;
    }
`;

export const TableWrapper = styled.div`
    display: block;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const SectionTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 48px;
    width: 100%;
    ${mediaQueries.sm} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 56px 16px 32px;
    }
`;

export const SectionTitle = styled(H2)`
    ${mediaQueries.sm} {
        padding-bottom: 24px;
    }
`;

export const StyledButton = styled(SecondaryButton)`
    min-width: 300px;
    ${mediaQueries.sm} {
        min-width: unset;
        width: 100%;
        margin: 0 32px;
        width: -moz-available;
        width: -webkit-fill-available;
    }
`;
