import { GarageHero } from 'components/GarageHero/GarageHero';
import Layout from 'layout/index';
import React, { useMemo } from 'react';
import { GaragesAndStoragesList } from 'sections/GaragesAndStoragesList/GaragesAndStoragesList';
import { getDisabledPages } from 'utils/utils';

interface GarazePageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: GarazePageProps) => {
    const garagesData = useMemo(() => {
        return pageContext?.data?.garages?.edges?.map((item: any) => ({
            ...item?.node?.investmentGarage,
            garageStatus: item?.node?.investmentGarage?.garageStatus?.name,
        }));
    }, [pageContext?.data?.garages?.edges]);

    const storagesData = useMemo(() => {
        return pageContext?.data?.storages?.edges?.map((item: any) => ({
            ...item?.node?.investmentStorageRoom,
            storageRoomStatus:
                item?.node?.investmentStorageRoom?.storageRoomStatus?.name,
        }));
    }, [pageContext?.data?.storages?.edges]);
    const searchByImage =
        pageContext?.data?.GaragesAndStoragesByImage?.investment?.searchByImage;

    const pageTitle = `${pageContext?.title} - Mieszkania - Garaże i komórki lokatorskie`;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.GaragesAndStoragesByImage?.investment
                    ?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                <GarageHero
                    investmentSlug={pageContext?.slug}
                    breadcrumbText="Mieszkania"
                    availableGarages={
                        pageContext?.data?.availableGarages?.count
                    }
                    availableStorageRooms={
                        pageContext?.data?.availableStorageRooms?.count
                    }
                    bookedGarages={pageContext?.data?.bookedGarages?.count}
                    bookedStorageRooms={
                        pageContext?.data?.bookedStorageRooms?.count
                    }
                    searchGarageOrStorageByImage={
                        searchByImage?.searchGarageOrStorageByImage
                    }
                    soldGarages={pageContext?.data?.soldGarages?.count}
                    soldStorageRooms={
                        pageContext?.data?.soldStorageRooms?.count
                    }
                />
                {garagesData && storagesData && (
                    <GaragesAndStoragesList
                        investmentSlug={pageContext?.slug}
                        garagesData={garagesData}
                        storagesData={storagesData}
                        additionalInfo={
                            searchByImage?.searchGarageOrStorageByImage
                                ?.storagesAdditionalInfo
                        }
                        hideStoragesMap={
                            searchByImage?.searchGarageOrStorageByImage
                                ?.hideStoragesMap
                        }
                    />
                )}
            </>
        </Layout>
    );
};
